import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OurTeamsService } from '../components/services/ourteam.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teamdetail',
  templateUrl: './teamdetail.component.html',
  styleUrls: ['./teamdetail.component.scss']
})
export class TeamdetailComponent {
  constructor(private ourteamService:OurTeamsService,private router:ActivatedRoute) { }
  getourteamModel!:any
  path = environment.serverUrl;
  ngOnInit(): void {
    this.router.params.subscribe(x => {
      this.getteamlist(x['id']);
          })
        }
        getteamlist(id:any){
          this.ourteamService.getid(id).subscribe((data:any)=> {
            this.getourteamModel = data;
          })
        }
}