<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{getourteamModel.username}}</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Services Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-content">
                        <!-- <h2>{{getourteamModel.username}}</h2> -->
                        <p [innerHTML]="getourteamModel.biografi"> </p>
                        <div class="services-details-one">
                            <!-- <img src="assets/img/services-details/services-details-1.jpg" alt="Image"> -->
                            <img src="{{path}}/WebImages/Teams/{{getourteamModel.photo}}" alt="Image">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->