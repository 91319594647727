<section class="our-approach-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Approach</span>
            <h2>To Enhance Your Cyber Defences Expert Will Support</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="approach-img">
                    <img src="assets/img/approach-img.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="approach-content">
                    <h3>Without stopping for a moment we give you best technology experience discussing from our expertise to stop threats being theft or damaged.</h3>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Trusted Partner</h3>
                                <p>sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Product Security</h3>
                                <p>sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>System Security</h3>
                                <p>sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Operational Security</h3>
                                <p>sed diam nonumy eirmod tempor invidunt ut labore et.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>