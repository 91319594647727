<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>İletişim</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">İletişim</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="main-contact-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="contact-title">
                            <h2>Bize yazın</h2>
                        </div>

                        <form id="contactForm" [formGroup]="form"  (ngSubmit)="submitForm()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-user'></i> -->
                                        <input type="text" name="firstName" formControlName="firstName" id="name" class="form-control" required placeholder="Adınız*">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-user'></i> -->
                                        <input type="text" name="lastName" formControlName="lastName" id="name" class="form-control" required placeholder="Soyadınız*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-user'></i> -->
                                        <input type="email"  id="email" name="email" formControlName="email" class="form-control" required placeholder="E-mail*">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-phone'></i> -->
                                        <input type="number" name="phone" id="phone" formControlName="phone" required class="form-control" placeholder="Telefonunuz">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-6">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-file'></i> -->
                                        <input type="text"name="subject" formControlName="subject" id="name" class="form-control" required placeholder="Senin Konun">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <!-- <i class='bx bx-envelope'></i> -->
                                        <textarea name="message" id="message" formControlName="message" class="form-control" cols="30" rows="8" required placeholder="Mesajın"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Mesaj Gönder <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <h3>İletişim bilgilerimiz</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> -->

                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            <span>Müsdav Genç İletişim Ofisi</span>
                            {{getsettingModel?.value}}
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <span>Telefon</span>
                            <a href="tel:+{{getphoneModel?.value}}">{{getphoneModel?.value}}</a>
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <span>Email</span>
                            <a href="mailto:{{getmailModel?.value}}">{{getmailModel?.value}}</a>
                        </li>
                    </ul>

                    <div class="sidebar-follow-us">
                        <h3>Bizi takip edin:
                        </h3>
    
                        <ul class="social-wrap">
                            <li><a target="_blank" href="{{getfacebookModel?.value}}"><i class="bx bxl-facebook"></i></a></li>
                            <li><a target="_blank" href="{{getinstagramModel?.value}}"><i class="bx bxl-instagram"></i></a></li>
                            <li><a target="_blank" href="{{getyoutubeModel?.value}}"><i class="bx bxl-youtube"></i></a></li>
                            <li><a target="_blank" href="{{gettwitterModel?.value}}"><img style="margin-top: -6px;border: 1px solid var(--bodyColor);border-radius: 50px; height: 26px" src="assets/img/twitter_2.png" alt=""></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.5548227631753!2d32.76051687725444!3d39.90659838652782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3462cd69c3903%3A0x1fc1bd60274b0a0a!2sAhmet%20Hamdi%20Akseki%20Camii!5e0!3m2!1str!2str!4v1711367367133!5m2!1str!2str"></iframe>
</div>
