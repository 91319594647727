<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Products List</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products List</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Products Area -->
<div class="product-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-1.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">Wireless security camera</a></h3>
                    <span>$190.00</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-2.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">White cctv camera</a></h3>
                    <span>$90.00</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-3.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">Ip camera security</a></h3>
                    <span>$120.0</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-4.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">Round cctv camera</a></h3>
                    <span>$50.00</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-5.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">Surveillance camera</a></h3>
                    <span>$100.00</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-products-box">
                    <div class="product-img">
                        <a routerLink="/products-details" class="d-block">
                            <img src="assets/img/product/product-6.jpg" alt="Image">
                        </a>
                    </div>
                    <h3><a routerLink="/products-details">Black web camera isolated</a></h3>
                    <span>$130.00</span>
                    <a routerLink="/cart" class="default-btn"><span>Add To Cart</span></a>
                </div>
            </div>
            
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current">1</span>
                    <a routerLink="/products" class="page-numbers">2</a>
                    <a routerLink="/products" class="page-numbers">3</a>
                    <a routerLink="/products" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Products Area -->