import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-solution',
  templateUrl: './homeone-solution.component.html',
  styleUrls: ['./homeone-solution.component.scss']
})
export class HomeoneSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
