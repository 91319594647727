import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentsService } from 'src/app/components/services/contents.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homethree-banner',
  templateUrl: './homethree-banner.component.html',
  styleUrls: ['./homethree-banner.component.scss']
})
export class HomethreeBannerComponent implements OnInit {

  path = environment.serverUrl;
  slidersModel!: any;
  constructor(private contentsService: ContentsService) { }
  ngOnInit(): void {
    this.getnewsList();
  }
  getnewsList() {
    this.contentsService.getsldiderList().subscribe(newsdata => {
      this.slidersModel = newsdata;
    })
  }
  recommendedCarOptions: OwlOptions = {
    margin: 0,
    nav: true,
    loop: false,
    dots: false,
    smartSpeed: 3000,
    
    navText: [
      "<i class='flaticon-arrows'></i>",
      "<i class='flaticon-right-arrow'></i>"
    ],
    autoplay: true,
    autoplaySpeed: 2000,
    center: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      }
    },
  };
}

