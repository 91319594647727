<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area page-title-style-two">
    <div class="container">
        <div class="page-title-content">
            <h2>Welcome to Waza</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Welcome to Waza</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start User Area -->
<section class="user-area-style ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form-action mb-50">
                    <div class="account-title">
                        <h2>Log in</h2>
                    </div>

                    <form>
                        <div class="form-group">
                            <label>Email or Phone</label>
                            <input class="form-control" type="text" name="name">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="password" name="password">
                        </div>

                        <div class="login-action">
                            <span class="log-rem">
                                <input id="remember" type="checkbox">
                                <label for="remember">Remember me!</label>
                            </span>
                            <span class="forgot-login"><a routerLink="/">Forgot your password?</a></span>
                        </div>

                        <button class="default-btn" type="submit"><span>Login now</span></button>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form-action">
                    <div class="account-title">
                        <h2>Register</h2>
                    </div>

                    <form>
                        <div class="form-group">
                            <label>Full name</label>
                            <input class="form-control" type="text" name="name">
                        </div>

                        <div class="form-group">
                            <label>Email address</label>
                            <input class="form-control" type="email" name="email">
                        </div>

                        <div class="form-group">
                            <label>Mobile no.</label>
                            <input class="form-control" type="text" name="Number">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="text" name="password">
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-6 col-sm-6">
                                <button class="default-btn register" type="submit"><span>Register now</span></button>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="right">
                                    <input id="remember-1" type="checkbox">
                                    <label for="remember-1">Show password ?</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End User Area -->