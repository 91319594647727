<app-navbar-style-three></app-navbar-style-three>


<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gönüllü Ol</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Gönüllü Ol</li>
            </ul>
        </div>
    </div>
</div>


<section class="get-in-touch-area ptb-100">
    <div class="container">
        <div class="section-title white-title">
            <!-- <span></span> -->
            <h2 style="color: #F37121;">Size nasıl hitap etmemizi istersiniz?</h2>
            <hr>
        </div>

        <form class="get-in-touch-form"  id="volunteerForm" [formGroup]="form"  (ngSubmit)="submitForm()">
            <div class="row">
                <div class="col-lg-8 col-sm-6" style="margin: auto;">
                    <div class="form-group row">
                        <!-- <label>Size nasıl hitap etmemizi istersiniz?</label> -->
                        <div class="form-check col-lg-6" style="text-align: center;">
                            <input class="form-check-input" type="radio" formControlName="appeal" id="appeal" value="1">
                            <label class="form-check-label" for="appeal">
                                Beyefendi 
                            </label>
                          </div>
                          <div class="form-check col-lg-6">
                            <input class="form-check-input" type="radio" formControlName="appeal" id="appeal" value="0">
                            <label class="form-check-label" for="appeal">
                                Hanımefendi
                            </label>
                          </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Ad</label>
                        <input type="text" class="form-control" id="firstName" name="firstName" formControlName="firstName">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Soyad</label>
                        <input type="text" class="form-control" id="lastName" name="lastName" formControlName="lastName">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label>Doğum Tarihi </label>
                        <input type="date" formControlName="birthDay" id="birthDay" name="birthDay" class="form-control">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>E-Posta</label>
                        <input type="email" class="form-control" id="email" name="email" formControlName="email">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Telefon</label>
                        <input type="text" class="form-control" id="phone" name="phone" formControlName="phone">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4">
                    <div class="form-group row">
                        <label>Gönüllü çalışmalarına daha önce katıldınız mı?</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="volunteerWork" id="volunteerWork" value="true">
                            <label class="form-check-label" for="volunteerWork">
                                Evet 
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" formControlName="volunteerWork" id="volunteerWork" value="false">
                            <label class="form-check-label" for="volunteerWork">
                                Hayır
                            </label>
                          </div>
                    </div>
                </div>
            
                <div class="col-lg-8 col-sm-8">
                    <div class="form-group row">
                        <!-- <label>Size nasıl hitap etmemizi istersiniz?</label> -->
                        <div class="form-check">
                            <label>Öğrenim durumu? </label>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="showDiffInvoice = !showDiffInvoice" id="diff">
                                <label class="form-check-label" for="diff">
                                    Lise
                                </label>
                            </div>
                            <div class="form-check ">
                                <input class="form-check-input" type="checkbox" (change)="showDiffInvoice1 = !showDiffInvoice1" id="diff">
                                <label class="form-check-label" for="diff">
                                    Üniversite
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="showDiffInvoice2 = !showDiffInvoice2" id="diff">
                                <label class="form-check-label" for="diff">
                                    Yüksek Lisans 
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" (change)="showDiffInvoice3 = !showDiffInvoice3" id="diff">
                                <label class="form-check-label" for="diff">
                                    Doktora 
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-sm-12">   
                    <div class="row" *ngIf="showDiffInvoice">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Okul</label>
                                <input type="text" formControlName="highSchool" id="highSchool" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">   
                    <div class="row" *ngIf="showDiffInvoice1">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Üniversite</label>
                                <input type="text" formControlName="universityName" id="universityName" class="form-control">
                              
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Bölüm</label>
                                <input type="text" formControlName="departmentName" id="departmentName" class="form-control">
                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Sınıf</label>
                                <input type="text" formControlName="class" id="class" class="form-control">
                                
                            </div>
                        </div>
                      

                       
                    </div>
                </div>
                <div class="col-sm-12">   
                    <div class="row" *ngIf="showDiffInvoice2">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Çalışma Alanı </label>
                                <input type="text" formControlName="workingArea" id="workingArea" class="form-control">
                              
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-sm-12">   
                    <div class="row" *ngIf="showDiffInvoice3">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label>Tez Konusu </label>
                                <input type="text" formControlName="thesisTopic" id="thesisTopic" class="form-control">
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                    <div class="form-group">
                        <label>Müsdav Genç faaliyetlerine hangi alanda katkı sağlamak istersiniz? </label>
                        <input type="text" class="form-control" id="volunteerParticipate" name="volunteerParticipate" formControlName="volunteerParticipate" >
                    </div>
                </div>
              

          
                
            </div>
            <button type="submit" class="default-btn">
                <span>Gönder</span>
            </button>
        </form>
    </div>
</section>