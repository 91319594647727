<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sıkça Sorulan Sorular</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Sıkça Sorulan Sorular</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<section class="faq-area white-bg ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="faq-img">
                    <img src="assets/img/SSS-01.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <div class="faq-title">
                        <h2>Sıkça Sorulan Sorular</h2>
                        <span>Cevaplarınızı Aşağıda Bulun</span>
                    </div>
                    <div class="accordion">
                        <div class="accordion-section" *ngFor="let item of faqModel">
                            <div class="accordion-header" [innerHTML]="item.question" (click)="toggleSection(item.id)" [ngClass]="{ 'open': isSectionOpen(item.id) }">
                                	                           </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(item.id) }">
                                <p [innerHTML]="item.answer"></p>
                            </div>
                        </div>
                        <!-- <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                	Müsdav Genç nedir?</div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>Gençler arasında kardeşlik ve gönül köprüleri kurmayı kendine ilke edinmiş yurt içinden ve yurt dışından gönüllülerin bir araya geldiği gençlik hareketidir.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                	Müsdav Genç hangi yaş gruplarına hitap eder?                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>14 – 29 yaş grubuna hitap etmektedir. </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                	Müsdav Genç ne tür aktiviteler düzenlemektedir?                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>Ulusal ve uluslararası eğitimler, atölyeler, kamplar, gençlik zirveleri ve insani yardım alanlarında faaliyet göstermektedir. </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                	Yurt içi temsilcilikleriniz var mı?</div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>Evet var, İstanbul da temsilciliğimiz bulunmaktadır.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(5)" [ngClass]="{ 'open': isSectionOpen(5) }">
                                	Yurt dışı temsilciliğiniz var mı?                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                                <p>Hayır, yok. </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(6)" [ngClass]="{ 'open': isSectionOpen(6) }">
                                	Müsdav Genç’e nasıl gönüllü olabilirim?                             </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                                <p>Web sitemizde bulunan ‘’Gönüllü Ol’’ formunu doldurarak gönüllümüz olabilirsiniz. </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(7)" [ngClass]="{ 'open': isSectionOpen(7) }">
                                	Etkinliklerden nasıl haberdar olurum?                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                                <p>Web sitemizi ve sosyal medya hesaplarımızı takip ederek çalışmalarımızdan haberdar olabilirsiniz.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(8)" [ngClass]="{ 'open': isSectionOpen(8) }">
                                	Müsdav Genç merkez binası nerede?                              </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(8) }">
                                <p>Sakarya, No:28, Ev Kadını Sk., 06230 Altındağ/Ankara </p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="faq-right-shape shape-three"><img src="assets/img/faq-right-shape.png" alt="Image"></div>
</section>
<!-- End FAQ Area -->

<!-- Start Get In Touch Area -->
<!-- <app-get-in-touch></app-get-in-touch> -->
<!-- End Get In Touch Area -->