import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RefarenceService } from '../../services/referance.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-partner',
	templateUrl: './partner.component.html',
	styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {


	referanceModel!: any;
	path = environment.serverUrl;


	constructor(private referanceService: RefarenceService) { }

	ngOnInit(): void {
		this.getreferancelist();
	}

	getreferancelist() {
		this.referanceService.getList().subscribe(data => {
			this.referanceModel = data;
		})
	}

	partnerSlides: OwlOptions = {
		loop: true,
		margin: 30,
		nav: false,
		dots: false,
		autoplay: true,
		smartSpeed: 1000,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>",
		],
		responsive: {
			0: {
				items: 2,
			},
			576: {
				items: 3,
			},
			768: {
				items: 4,
			},
			1200: {
				items: 5,
			}
		}
	}

}