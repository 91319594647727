<section class="pricing-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Security Packages</span>
            <h2>We Have Different Types Of Pricing Plans</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing overly-one">
                    <div class="overly-two">
                        <div class="pricing-title">
                            <h3>Basic</h3>
                            <h2>Free</h2>
                            <span>/Per month</span>
                        </div>
                        <ul>
                            <li>30 Days Product Testing</li>
                            <li>Upgrade Anytime Protection</li>
                            <li>Unlimited Malware Removal</li>
                            <li>24/7 Live Assistance</li>
                            <li>Scan Every 12 Hrs</li>
                            <li>Configure Software</li>
                        </ul>
                        <a routerLink="/" class="default-btn"><span>Read More</span></a>
                        <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                        <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing overly-one active">
                    <div class="overly-two">
                        <div class="pricing-title">
                            <h3>Popular</h3>
                            <h2><Sub>$</Sub>29</h2>
                            <span>/Per month</span>
                        </div>
                        <ul>
                            <li>40 Days Product Testing</li>
                            <li>Upgrade Anytime Protection</li>
                            <li>Unlimited Malware Removal</li>
                            <li>24/7 Live Assistance</li>
                            <li>Scan Every 13 Hrs</li>
                            <li>Configure Software</li>
                        </ul>
                        <a routerLink="/" class="default-btn"><span>Read More</span></a>
                        <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                        <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing overly-one">
                    <div class="overly-two">
                        <div class="pricing-title">
                            <h3>Basic</h3>
                            <h2><Sub>$</Sub>59</h2>
                            <span>/Per month</span>
                        </div>
                        <ul>
                            <li>50 Days Product Testing</li>
                            <li>Upgrade Anytime Protection</li>
                            <li>Unlimited Malware Removal</li>
                            <li>24/7 Live Assistance</li>
                            <li>Scan Every 14 Hrs</li>
                            <li>Configure Software</li>
                        </ul>
                        <a routerLink="/" class="default-btn"><span>Read More</span></a>
                        <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="Image"></div>
                        <div class="pricing-shape-2"><img src="assets/img/pricing-shape-2.png" alt="Image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>