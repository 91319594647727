<section class="solution-area solution-area-three white-bg pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <div class="solution-title">
                        <span>All-in Solution</span>
                        <h2>Our experts are providing and planning 24/7 technical support services with product and security consultation.</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">Product Consultation</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
                                    <span>01</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">Security Consultation</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
                                    <span>02</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 offset-md-3 offset-lg-0">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">24/7 Technical Support</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
                                    <span>03</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solution-img-two text-center">
                    <img src="assets/img/solution-img-2.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>