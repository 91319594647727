import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentsService } from 'src/app/components/services/contents.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homethree-blog',
  templateUrl: './homethree-blog.component.html',
  styleUrls: ['./homethree-blog.component.scss']
})
export class HomethreeBlogComponent implements OnInit {
  path = environment.serverUrl;

  newsModel!: any;

  constructor(private contentsService: ContentsService) { }

  ngOnInit(): void {
    this.getnewsList();
  }

  getnewsList() {
    this.contentsService.getnewsList().subscribe(newsdata => {
      this.newsModel = newsdata;
    })
  }


  createSlug(title: string): string {
	title = title.trim().toLowerCase().replace(/\s+/g, '-');
	const turkishChars = "çğıöşüÇĞİÖŞÜİ";
	const englishChars = "cgiosuCGIOSUı";
	
	let result = "";
	for (let i = 0; i < title.length; i++) {
	  const index = turkishChars.indexOf(title[i]);
	  if (index !== -1) {
		result += englishChars[index];
	  } else {
		result += title[i];
	  }
	}
	return result;
  }

  partnerSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
    smartSpeed: 1000,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>",
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 3,
			},
			768: {
				items: 4,
			},
			1200: {
				items: 3,
			}
		}
	}


}
