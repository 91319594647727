import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OurTeamsService } from '../../services/ourteam.service';

@Component({
  selector: 'app-team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss']
})
export class TeamPageComponent implements OnInit {
  constructor(private ourteamsService: OurTeamsService) { }
  path = environment.serverUrl;
  ourteamModel!: any;
  teamsModel!: any;
  ngOnInit(): void {
    this.getourteamslist();
    this.getteamslist();
  }
  getourteamslist() {
    this.ourteamsService.getList().subscribe(data => {
      this.ourteamModel = data.filter(x => x.isActive == true);
    })
  }
  getteamslist() {
    this.ourteamsService.getTeams().subscribe(data => {
      this.teamsModel = data;
    })
  }
  showItemModal(id, displayStyle) {
    var modal = document.getElementById('modal_' + id)
    if (displayStyle === 'block') {
      modal.style.display = 'block'
    }
    else {
      modal.style.display = 'none'
    }
  }
}