<section class="row text-center">
    <owl-carousel-o [options]="recommendedCarOptions" >
                <ng-container *ngFor="let data of slidersModel">
                  <ng-template carouselSlide>
            <div class="rental-car-item">
                <div class="listing-item mb-0">										
                    <div class="listing-img">
                        <a routerLink="{{data.link}}">
                            <!-- <img [src]="data.img" class="img-fluid" alt="Toyota"> -->
                            <img img [src]="path+'/WebImages/Announcements/'+data.image" alt="Image">
                        </a>
                    </div>									
                </div>
            </div>
        </ng-template>
    </ng-container>
  </owl-carousel-o>
</section>
