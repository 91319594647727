<section class="about-us-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12" style="margin-top: -8%;">
                <div class="about-img">
                    <img src="assets/img/about.jpg" alt="Image" style="border-radius: 11px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12" style="margin-top: -4%;">
                <div class="about-content">
                    <div class="about-title">
                        <!-- <span>About Us</span> -->
                        <h2 >Uluslararası Müslüman Topluluklarla Dayanışma Vakfı (MÜSDAV) gençlere yönelik faaliyetlerini kurumsal bir çerçevede planlayıp uygulayabilmek amacıyla kurulmuş gençlik teşkilatıdır.

                        </h2>
                    </div>
                    <div class="tab">
                        <ul class="nav-tabset">
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span (click)="switchTab($event, 'tab1')">
                                    MÜSDAV Genç’in Gayesi 
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                                <span (click)="switchTab($event, 'tab2')">
                                    Temel Prensipler 
                                </span>
                            </li>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                                <span (click)="switchTab($event, 'tab3')">
                                    Faaliyet Alanları 
                                </span>
                            </li>
                        </ul>
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <p style="text-align: justify;color: #000219;font-weight: 600;">Uluslararası Müslüman Topluluklarla Dayanışma Vakfı’nın gençlere yönelik gerçekleştirdiği program ve projeleri desteklemek, geliştirmek ve katkı sağlamak, yurt içi ve yurt dışında  gençlere yönelik gençlik buluşmaları, zirveler, sempozyumlar, çalıştaylar, söyleşiler ve gençlik kampları düzenlemek, yerel ve uluslararası gençlik platformları, sivil toplum kuruluşları ile iş birliği içerisinde bulunmak, İslam dünyasında yaşanan sorunların ortak çözümü için istişare metotları ve stratejiler geliştirmek, gençler arasında birlik, dayanışma, kardeşlik ve paylaşma duygularını geliştirerek gönül köprüleri kurmak. </p>
                                <!-- <ul>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                </ul> -->
                            </div>
                            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                                <!-- <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p> -->
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> İnanç ve Azim </li>
                                    <li><i class="bx bx-check-circle"></i> Bilgi ve Hikmet </li>
                                    <li><i class="bx bx-check-circle"></i> Birlik ve Beraberlik </li>
                                    <li><i class="bx bx-check-circle"></i> Ehliyet ve Liyakat </li>
                                    <li><i class="bx bx-check-circle"></i> Dürüstlük ve Şeffaflık  </li>
                                    <li><i class="bx bx-check-circle"></i> Ortak Akıl ve Katılımcılık  </li>
                                    <li><i class="bx bx-check-circle"></i> Yenilik ve İnovasyon </li>
                                </ul>
                            </div>
                            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                                <!-- <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p> -->
                                <ul>
                                    <li><i class="bx bx-check-circle"></i>Uluslararası Müslüman Topluluklarla Dayanışma Vakfı’nın projelerini desteklemek, geliştirmek ve katkı sağlamak. </li>
                                    <li><i class="bx bx-check-circle"></i>Yurt içi ve yurt dışında 16-30 yaş aralığında bulunan gençlere yönelik gençlik buluşmaları, zirveler, sempozyumlar, çalıştaylar, söyleşiler ve gençlik kampları düzenlemek. </li>
                                    <li><i class="bx bx-check-circle"></i>Yerel ve uluslararası düzeyde başarı elde etmiş gençleri desteklemek ve gelişimlerine katkı sağlamak.</li>
                                    <li><i class="bx bx-check-circle"></i>Türkiye’ye öğrenim görmek üzere gelen misafir öğrencilere yönelik faaliyetler gerçekleştirmek.</li>
                                    <li><i class="bx bx-check-circle"></i>Toplumun çoğunluğunu Müslümanların oluşturmadığı bölgeler öncelikli olmak üzere yurt dışında gençlik kuruluşları ile iş birliği içerisinde olmak ve gençlerle din, dil, kültür, sanat, eğitim, spor, çevre, insan hakları, iletişim ve medya konularında ortak çalışmalar yürütmek. </li>
                                    <li><i class="bx bx-check-circle"></i>Yerel ve uluslararası gençlik platformları, sivil toplum kuruluşları ile iş birliği içerisinde bulunmak. İslam dünyasında yaşanan sorunların ortak çözümü için istişare metotları ve stratejiler geliştirmek.</li>
                                    <li><i class="bx bx-check-circle"></i>İslâm dininin insanlığa huzur ve barış getirmeyi amaçlayan evrensel ilke ve değerlerini gençlere anlatmak ve gençler arasında birlik, dayanışma, kardeşlik ve paylaşma duygularını geliştirerek gönül köprüleri kurmak. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>