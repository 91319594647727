<div class="container">
    <div class="partner-slider">
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide *ngFor="let item of referanceModel">
                <div class="partner-item">
                    <a href="#">
                        <img img [src]="path+'/Referances/'+item.image" alt="Image"></a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>