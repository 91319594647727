<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Our Approach Area -->
<section class="our-approach-area-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our approach</span>
            <h2>How can i protect my website form cyber attacks</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h3>Identifying Threats</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-padlock"></i>
                        </div>
                        <h3>Keep Software Updated </h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-url"></i>
                        </div>
                        <h3>Check Website Url</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <i class="flaticon-antivirus"></i>
                        </div>
                        <h3>Use Antivirus Software</h3>
                        <p>Lorem ipsum dolor amet, cons ectetur adipiscing elit, sed eiu smod tempor incididunt.</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Approach Area -->

<!-- Start Services Area -->
<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Cybersecurity services</span>
            <h2>You can protect your organization’s cybersecurity by services us</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-1.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Website Scanning</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-2.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Malware Removal</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-3.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Cloud Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-4.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Data Protection</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-5.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Application Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-6.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Threat Hunter</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-7.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Secure Managed IT</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-8.jpg" alt="Image">
                        </a>
                    </div>

                    <div class="services-content">
                        <h3><a routerLink="/services-details">Networking Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Area -->