<!-- Start Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>MÜSDAV GENÇ</h3>

                    <!-- <a routerLink="/" class="logo"><img src="assets/img/MUSDAVGENC_LOGO_YUSEK_BOYUT.png" alt="Image" style="width: 50%;"></a> -->
                    <p>{{getdescriptionModel?.value}}.

                    </p>
                    <ul class="social-icon">
                        <li><a target="_blank" href="{{getfacebookModel?.value}}"><i class="bx bxl-facebook" style="margin-left: 30%;"></i></a></li>
                        <li><a target="_blank" href="{{getinstagramModel?.value}}"><i class="bx bxl-instagram"></i></a></li>
                        <li><a target="_blank" href="{{getyoutubeModel?.value}}"><i class="bx bxl-youtube"></i></a></li>
                        <li><a target="_blank" href="{{gettwitterModel?.value}}"><img style="margin-top: -6px;" src="assets/img/twitter_2.png" alt=""></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>İletişim bilgilerimiz</h3>

                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            {{getsettingModel?.value}}
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:{{getmailModel?.value}}">{{getmailModel?.value}}</a>
                            
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <a href="tel:+{{getphoneModel?.value}}">{{getphoneModel?.value}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Projeler</h3>

                    <ul class="import-link">
                        <li><a target="_blank" href="https://ugz.musdavgenc.org.tr/">Uluslararası Gençlik Zirvesi</a></li>
                        <li><a target="_blank" href="https://celebiprogrami.com/">Çelebi Programı</a></li>
                        <li><a target="_blank" href="https://itri.musdav.org.tr/">Itrî Kültür & Sanat</a></li>
                        <li><a target="_blank" href="https://hafiz.musdav.org.tr/">Bir Hafız Yetiştiriyorum</a></li>
                        <li><a target="_blank" href="https://sevapp.com/">Sevapp</a></li>
                        <li><a target="_blank" href="https://benimcamim.com/">Benim Camim</a></li>
                        <li><a target="_blank" href="https://www.awqatsalah.com/">Awqat salah</a></li>
                        </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Hızlı Linkler</h3>

                    <ul class="import-link">
                        <li><a routerLink="/">Anasayfa</a></li>
                        <li><a routerLink="/about">Hakkımızda</a></li>
                        <li><a routerLink="/blog-grid">Haberler</a></li>
                        <li><a routerLink="/team">Ekibimiz</a></li>
                        <li><a routerLink="/faq">Sıkça Sorulan Sorular</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area" >
    <div class="container">
        <div class="row align-items-center">
            <!-- <div class="col-lg-12">
                <p>© Designed and Developed by <a href="https://vakifglobal.com" target="_blank"><img src="assets/img/vglogo.png" alt="Image" style="width: 120px;"></a></p>
            </div> -->
            <div class="col-lg-6 col-md-6">
                <ul class="footer-menu">
                    <p>© Designed and Developed by <a href="https://vakifglobal.com" target="_blank"><img src="assets/img/vglogo.png" alt="Image" style="width: 120px;"></a></p>

                </ul>
            </div>
        </div>
    </div>
</div>
