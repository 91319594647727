<app-navbar-style-three></app-navbar-style-three>

<app-homethree-banner></app-homethree-banner>

<!-- <app-homethree-features></app-homethree-features> -->

<!-- <app-homethree-approach></app-homethree-approach> -->

<!-- <app-homethree-solution></app-homethree-solution> -->

<!-- <app-homethree-services></app-homethree-services> -->

<!-- <app-homethree-pricing></app-homethree-pricing> -->


<app-funfacts></app-funfacts>
<app-homethree-blog></app-homethree-blog>

<!-- 
<section class="team-area team-area-three white-bg pt-100 pb-70">
    <app-team></app-team>
</section> -->

<!-- <app-homethree-protect></app-homethree-protect> -->

<!-- <app-homethree-faq></app-homethree-faq> -->


<div class="partner-area bg-color ptb-70">
    <app-partner></app-partner>
</div>