import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-protect',
  templateUrl: './homethree-protect.component.html',
  styleUrls: ['./homethree-protect.component.scss']
})
export class HomethreeProtectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
