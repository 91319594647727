import { Component, OnInit } from '@angular/core';
import { ContentsService } from '../../services/contents.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details-page',
  templateUrl: './blog-details-page.component.html',
  styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

  constructor(private contentsService:ContentsService,private router:ActivatedRoute, private title:Title, private meta:Meta) { }
  path = environment.serverUrl;
  getnewsModel!:any;
  newsModel!:any;


  ngOnInit(): void {
  
    this.router.params.subscribe(x => {
      this.getnewslist(x['id']);
          })
          this.getnewsList();
  }


  getnewslist(id:any){
    this.contentsService.getid(id).subscribe((data:any)=> {
      this.title.setTitle(data.title)
       this.meta.updateTag({
          property:'og:description',
          content:data.title
        }
      )

      this.meta.updateTag({
        property:'og:image',
        content:this.path+'/WebImages/Announcements/'+data.image
      }
    )
      // {
      //   property:'og:image',
      //   content:this.path+'/WebImages/Announcements/'+data.image
      // }
      this.getnewsModel = data;
    })

  }

  getnewsList(){
    this.contentsService.getnewsList().subscribe(newsdata=> {
      this.newsModel = newsdata;
    })
  }


}
