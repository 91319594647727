import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ContactApplicationService } from '../components/services/contactApplication.service';
import { CommunicationForm } from '../components/models/communicationForm';

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.scss']
})
export class VolunteerComponent {
  form!: FormGroup;
  showDiffInvoice!: boolean;
  showDiffInvoice1!: boolean;
  showDiffInvoice2!: boolean;
  showDiffInvoice3!: boolean;
  constructor(private formBuilder: FormBuilder,private contactApplicationService:ContactApplicationService) { }

  ngOnInit(): void {
    this.volunteerForm();
  ;
  }

  volunteerForm() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      appeal:['',],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone:['', Validators.required],
      birthDay:['',],
      highSchool: ['',],
      universityName: ['',],
      departmentName: ['',],
      educationStat: ['',],
      class: ['',],
      workingArea: ['',],
      thesisTopic: ['',],
      volunteerWork: [false,],
      volunteerParticipate: [''],
      additionArea: ['',],
      isConfirm:[false],
      modifiedDate:[new Date()],
      creationDate:[new Date()]

      
    })
  }

  submitForm() {
    console.log(this.form)
    if (this.form.valid) {
      const volunteerForm: any = Object.assign(this.form.value);
      Swal.fire({
        title: 'Talebiniz gönderilsin mi?',
        showDenyButton: true, 
        confirmButtonText: 'Evet',confirmButtonColor:"#238dc1",
        denyButtonText: `Hayır`, denyButtonColor:"#b47f00",
      }).then((response) => {
        if (response.isConfirmed) {
          this.contactApplicationService.volunteerSend(volunteerForm).subscribe((data: any) => {
            if (data) {
              Swal.fire({
                title: 'Başarılı',
                text: 'Gönüllü ol talebiniz alınmıştır...',
                icon: 'success',iconColor:"#89dc65",
                confirmButtonText: 'Tamam',  confirmButtonColor:"#89dc65" ,
               }  ).then(function (result) {
                if (result.value) {
                  window.location.href = 'volunter';
                }
               })
            }
          }); 
        } 
      } );
    } else {
      Swal.fire({
        title: 'Gönüllü Ol Bilgilerini Doldurun',
        icon: 'warning',iconColor:"#d4c201",
        confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
       }  )
    }
  }
}
