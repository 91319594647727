import { Component, OnInit } from '@angular/core';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  getsettingModel: any;
  getphoneModel:any;
  getmailModel:any;
  getinstagramModel!:any;
  getfacebookModel!:any;
  gettwitterModel!:any;
  getdescriptionModel!:any;
  getyoutubeModel!:any;
  constructor(private settingService: SettingService) { }

  ngOnInit(): void {
    this.getsettingList();
    this.getphoneList();
    this.getmailList();
    this.getinstagramList();
    this.getfacebookList();
    this.gettwitterList();
    this.getdescriptionList();
    this.getyoutubeList();
  }
 
  getsettingList() {
    this.settingService.getid("adres", 1).subscribe(data => {
      this.getsettingModel = data;
    })
  }
  getphoneList() {
    this.settingService.getid("telefon", 1).subscribe(data => {
      this.getphoneModel = data;
    })
  }
  getmailList() {
    this.settingService.getid("mail", 1).subscribe(data => {
      this.getmailModel = data;
    })
  }
 
  getinstagramList() {
    this.settingService.getid("instagram", 1).subscribe(data => {
      this.getinstagramModel = data;
    })
  }
  getfacebookList() {
    this.settingService.getid("facebook", 1).subscribe(data => {
      this.getfacebookModel = data;
    })
  }

  gettwitterList(){
    this.settingService.getid("twitter",1).subscribe(data => {
      this.gettwitterModel = data;
    })
  }

  getdescriptionList(){
    this.settingService.getid("aciklama",1).subscribe(data => {
      this.getdescriptionModel = data;
    })
  }
  getyoutubeList(){
    this.settingService.getid("youtube",1).subscribe(data => {
      this.getyoutubeModel = data;
    })
  }
  
  
  

}
