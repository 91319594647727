<section class="testimonials-area ptb-100">
    <div class="container">
        <div class="testimonials">
            <span>What our customers say</span>

            <div class="testimonials-slider">
                <owl-carousel-o [options]="testimonialsSlides">
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <i class="flaticon-quote"></i>
                            <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p> 
                            <ul>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                            </ul>
                            <h3>Jastin Anderson</h3>
                            <span>CEO</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="testimonials-item">
                            <i class="flaticon-quote"></i>
                            <p>“Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.”</p> 
                            <ul>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                            </ul>
                            <h3>Juhon Anderson</h3>
                            <span>Manager</span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>