<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Hakkımızda</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Hakkımızda</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<app-aboutpage-about></app-aboutpage-about>

<!-- <app-our-challenges></app-our-challenges> -->

<!-- <app-homeone-services></app-homeone-services> -->

<!-- <app-homeone-solution></app-homeone-solution> -->

<!-- <app-get-in-touch></app-get-in-touch> -->

<!-- <app-homeone-protect></app-homeone-protect> -->

<!-- <app-feedback></app-feedback> -->

<!-- <div class="partner-area ptb-70">
    <app-partner></app-partner>
</div> -->