<app-navbar-style-three></app-navbar-style-three>
<meta title="{{getnewsModel.title}}">
<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{getnewsModel.title}}</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Haberler</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-content">
                    <div class="blog-details-img">
                        <img img [src]="path+'/WebImages/Announcements/'+getnewsModel.image" alt="Image">
                    </div>

                    <div class="blog-top-content">
                        <div class="news-content">
                            <ul class="admin">
                                <!-- <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Posted by: Carl Bradshaw</a></li> -->
                                <!-- <li><a routerLink="/blog-grid"><i class="bx bx-comment"></i> No comments</a></li> -->
                                <li class="float"><i class="bx bx-calendar-alt"></i> {{getnewsModel.creationDate | date:'dd/MM/yyyy' :'TR'}}</li>
                            </ul>
                            <h3>{{getnewsModel.title}}</h3>
                            <p  style="text-align: justify;" innerHTML="{{getnewsModel.detail}}">{{getnewsModel.detail}}</p>
                            
                        </div>

                        <!-- <blockquote>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore dolore magna aliqua.”</p>
                            <i class="bx bxs-quote-alt-left"></i>
                        </blockquote> -->

                      
                        <!-- <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-1.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-2.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>
                        </div> -->

                       

                        <!-- <div class="tag-list">
                            <span>Tags:</span>

                            <ul>
                                <li><a routerLink="/blog-grid">Cybersecurity</a></li>
                                <li><a routerLink="/blog-grid">Seaacurity</a></li>
                                <li><a routerLink="/blog-grid">Hackers</a></li>
                                <li><a routerLink="/blog-grid">Cybercrime</a></li>
                            </ul>
                        </div> -->
                    </div>

                    <!-- <div class="comments">
                        <h3>Yorumlar (2)</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog-details/comments-img-1.jpg" alt="Image">
                                <h3>Juanita Jones</h3>
                                <span>Monday, September 20, 2023</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                            <li>
                                <img src="assets/img/blog-details/comments-img-2.jpg" alt="Image">
                                <h3>Ward F. Nelson</h3>
                                <span>Monday, September 20, 2023</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                        </ul>
                    </div>

                    <div class="leave-reply">
                        <h3>Yorum bırakın</h3>
                        <p>E-posta hesabınız yayımlanmayacak. Zorunlu alanlar * ile işaretlenmiştir <span class="star">*</span></p>

                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Ad-Soyad<span class="star">*</span></label>
                                        <input type="text" name="name" id="name" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>E-Posta<span class="star">*</span></label>
                                        <input type="email" name="email" id="email" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Your website</label>
                                        <input type="text" name="your-website-link" id="your-website-link" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Yorumun</label>
                                        <textarea name="message" class="form-control" id="message" rows="8"></textarea>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="checkbox" id="chb2">
                                        <span>Bir dahaki sefere yorum yaptığımda kullanılmak üzere adımı, e-postamı ve web sitemi bu tarayıcıya kaydet.                                        </span>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn"><span>Yorum Gönder</span></button>
                                </div>
                            </div>
                        </form>
                    </div> -->


                    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-xl">Extra large modal</button> -->

                    <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          ...
                        </div>
                      </div>
                    </div>

                    

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <!-- <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div> -->

                    <!-- <div class="sidebar-widget recent-post">
                        <h3 class="widget-title">Son Gönderiler</h3>
                        
                        <ul>
                            <li  *ngFor="let item of newsModel">
                                <a routerLink="/blog-details">
                                    <img style="height: 70px;" img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image">
                                </a>
                                <span>{{item.creationDate  | date:'dd/MM/yyyy' :'TR'}}</span>
                            </li>
                          
                        </ul>
                    </div> -->

                    <!-- <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Threat Hunter</a></li>
                            <li><a routerLink="/blog-right-sidebar">Incident Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure Managed IT</a></li>
                            <li><a routerLink="/blog-right-sidebar">Server Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration Testing</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">August 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">June 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">April 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">January 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">December 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">November 2023</a></li>
                        </ul>
                    </div> -->

                    <!-- <div class="sidebar-widget tags mb-0">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber</a></li>
                            <li><a routerLink="/blog-right-sidebar">Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network</a></li>
                            <li><a routerLink="/blog-right-sidebar">Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure</a></li>
                            <li><a routerLink="/blog-right-sidebar">Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->