import { Component, OnInit, HostListener } from '@angular/core';
import { FrontMenüService } from '../../services/frontMenü.service';

@Component({
    selector: 'app-navbar-style-three',
    templateUrl: './navbar-style-three.component.html',
    styleUrls: ['./navbar-style-three.component.scss']
})
export class NavbarStyleThreeComponent implements OnInit {
    constructor(private frontMenüService: FrontMenüService) { }
    frontModel!: any;
    ngOnInit(): void {
        this.getfrontist();
    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    getfrontist() {
        this.frontMenüService.getList().subscribe(data => {
            this.frontModel = data;
        })
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }
}