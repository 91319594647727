import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ProductsPageComponent } from './components/pages/products-page/products-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ServicesPageStyleOneComponent } from './components/pages/services-page-style-one/services-page-style-one.component';
import { ServicesPageStyleThreeComponent } from './components/pages/services-page-style-three/services-page-style-three.component';
import { ServicesPageStyleTwoComponent } from './components/pages/services-page-style-two/services-page-style-two.component';
import { SolutionsDetailsPageComponent } from './components/pages/solutions-details-page/solutions-details-page.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { TeamdetailComponent } from './teamdetail/teamdetail.component';
import { VolunteerComponent } from './volunteer/volunteer.component';

const routes: Routes = [
    {path: '', component: HomeThreeComponent},
    {path: 'home-two', component: HomeTwoComponent},
    // {path: 'home-three', component: HomeThreeComponent},
    {path: 'about', component: AboutPageComponent},
    {path: 'solutions', component: SolutionsPageComponent},
    {path: 'solutions-details', component: SolutionsDetailsPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'team', component: TeamPageComponent},
    {path: 'pricing', component: PricingPageComponent},
    {path: 'coming-soon', component: ComingSoonPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'profile-authentication', component: ProfileAuthenticationPageComponent},
    {path: 'haberler', component: BlogGridPageComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarPageComponent},
    {path: 'haber-detay/:id/:title', component: BlogDetailsPageComponent},
    {path: 'products', component: ProductsPageComponent},
    {path: 'cart', component: CartPageComponent},
    {path: 'checkout', component: CheckoutPageComponent},
    {path: 'products-details', component: ProductsDetailsPageComponent},
    {path: 'terms-conditions', component: TermsConditionsPageComponent},
    {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
    {path: 'services-style-one', component: ServicesPageStyleOneComponent},
    {path: 'services-style-two', component: ServicesPageStyleTwoComponent},
    {path: 'services-style-three', component: ServicesPageStyleThreeComponent},
    {path: 'services-details', component: ServicesDetailsPageComponent},
    {path: 'team-details/:id',component: TeamdetailComponent},
    {path: 'gonulluol', component:VolunteerComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }