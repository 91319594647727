import { Component, OnInit } from '@angular/core';
import { FaqService } from '../../services/faq.service';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
    faqModel!: any;
    constructor(private faqService: FaqService) { }
    ngOnInit(): void {
        this.getfaqlist();
    }
    // Accordion
    contentHeight: number = 0;
    openSectionIndex: number = -1;
    toggleSection(index: number): void {
        if (this.openSectionIndex === index) {
            this.openSectionIndex = -1;
        } else {
            this.openSectionIndex = index;
            this.calculateContentHeight();
        }
    }
    isSectionOpen(index: number): boolean {
        return this.openSectionIndex === index;
    }
    calculateContentHeight(): void {
        const contentElement = document.querySelector('.accordion-content');
        if (contentElement) {
            this.contentHeight = contentElement.scrollHeight;
        }
    }
    getfaqlist() {
        this.faqService.getList().subscribe(data => {
            this.faqModel = data;
        })
    }
}