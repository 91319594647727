<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Ekibimiz</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Ekibimiz</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6" style="margin: auto;">
                <div class="single-team">
                    <a (click)="showItemModal(ourteamModel[0].id, 'block')">
                        <img  src="{{path}}/WebImages/Teams/{{ourteamModel[0].photo}}" alt="Image"
                            style="border-radius: 90px;width: 85%;">
                    </a>
                    <div class="team-content">
                        <h3>{{ourteamModel[0].username}}</h3>
                        <ul>
                            <span>{{ourteamModel[0].categoryName}}</span>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"> 
            <ng-container *ngFor="let item of ourteamModel ;let i = index">
                <div class="col-lg-4 col-sm-6" *ngIf="i!=0">
                    <div class="single-team"  >
                        <a (click)="showItemModal(item.id, 'block')">
                            <img src="{{path}}/WebImages/Teams/{{item.photo}}" alt="Image"
                                style="border-radius: 90px;width: 80%;">
                        </a>    
                        <div class="team-content">
                            <h3>{{item.username}}</h3>
                            <ul>
                                <span>{{item.categoryName}}</span>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
           

        </div>
    </div>
</section>

<div *ngFor="let item of teamsModel" id="modal_{{item.id}}" class="w3-modal">
    <div class="w3-modal-content">
      <div class="w3-container" style="margin-top: 10%;"><br>
        <span (click)="showItemModal(item.id, 'none')" class="w3-button w3-display-topright">&times;</span>
        <p [innerHTML]="item.biografi"></p>
      </div>
    </div>
  </div>
<!-- End Team Area -->