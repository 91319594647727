<section class="blog-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Latest News</span>
            <h2>Read The Latest Articles From Us</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-4.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span>September 20, 2023</span>
                        <h3><a routerLink="/blog-details">The security risks of changing package owners</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-5.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span>September 19, 2023</span>
                        <h3><a routerLink="/blog-details">Tips to Protecting Your Business and Family</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-6.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span>September 18, 2023</span>
                        <h3><a routerLink="/blog-details">Protect Your Workplace from Cyber Attacks</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>