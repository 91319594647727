import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactApplicationService } from '../../services/contactApplication.service';
import { CommunicationForm } from '../../models/communicationForm';
import Swal from 'sweetalert2';
import { SettingService } from '../../services/setting.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    form!: FormGroup;
 getsettingModel: any;
  getphoneModel:any;
  getmailModel:any;
   getinstagramModel!:any;
  getfacebookModel!:any;
  gettwitterModel!:any;
  getyoutubeModel!:any;


    constructor(private formBuilder: FormBuilder, private contactApplicationService:ContactApplicationService,private settingService: SettingService) { }

    ngOnInit(): void {
        this.communicationForm();
        this.getsettingList();
        this.getphoneList();
        this.getmailList();
          this.getinstagramList();
    this.getfacebookList();
  this.gettwitterList();
  this.getyoutubeList();
      }

      communicationForm() {
        this.form = this.formBuilder.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          email: ['', Validators.compose([Validators.required, Validators.email])],
          subject: ['', Validators.required],
          message: ['', Validators.required],
          phone:['', Validators.required]
        })
      }
    
      submitForm() {
        console.log(this.form)
        if (this.form.valid) {
          
          const communicationForm: CommunicationForm = Object.assign(this.form.value);
          Swal.fire({
            title: 'Talebiniz gönderilsin mi?',
            showDenyButton: true, 
            confirmButtonText: 'Evet',confirmButtonColor:"#238dc1",
            denyButtonText: `Hayır`, denyButtonColor:"#b47f00",
          }).then((response) => {
            if (response.isConfirmed) {
              this.contactApplicationService.communicationSend(communicationForm).subscribe((data: any) => {
                if (data) {
                  Swal.fire({
                    title: 'Başarılı',
                    text: 'İletişim talebiniz alınmıştır...',
                    icon: 'success',iconColor:"#89dc65",
                    confirmButtonText: 'Tamam',  confirmButtonColor:"#89dc65" ,
                   }  ).then(function (result) {
                    if (result.value) {
                      window.location.href = 'contact';
                    }
                   })
                }
                
              }); 
            } 
          } );
        } else {
          Swal.fire({
            title: 'İletişim bilgilerini doldurun',
            icon: 'warning',iconColor:"#d4c201",
            confirmButtonText: 'Tamam', confirmButtonColor:"#89dc65",
           }  )
        }
      }

    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var subject = form.subject;
        console.log(subject);
        
        var message = form.message;
        console.log(message);
    }




     getsettingList() {
    this.settingService.getid("adres", 1).subscribe(data => {
      this.getsettingModel = data;
    })
  }
  getphoneList() {
    this.settingService.getid("telefon", 1).subscribe(data => {
      this.getphoneModel = data;
    })
  }
  getmailList() {
    this.settingService.getid("mail", 1).subscribe(data => {
      this.getmailModel = data;
    })
  }


    getinstagramList() {
    this.settingService.getid("instagram", 1).subscribe(data => {
      this.getinstagramModel = data;
    })
  }
  getfacebookList() {
    this.settingService.getid("facebook", 1).subscribe(data => {
      this.getfacebookModel = data;
    })
  }

  gettwitterList(){
    this.settingService.getid("twitter",1).subscribe(data => {
      this.gettwitterModel = data;
    })
  }
  getyoutubeList(){
    this.settingService.getid("youtube",1).subscribe(data => {
      this.getyoutubeModel = data;
    })
  }

}