import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homethree-solution',
  templateUrl: './homethree-solution.component.html',
  styleUrls: ['./homethree-solution.component.scss']
})
export class HomethreeSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
