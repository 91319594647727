<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Haberler</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Anasayfa</a></li>
                <li class="active">Haberler</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of newsModel">
                <div class="single-blog-posts">
                    <a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)">
                        <!-- <img src="assets/img/blog/blog-4.jpg" alt="Image"> -->
                        <img img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image">

                    </a>
                    
                    <div class="single-blog-content">
                        <!-- <span>Security</span> -->
                        <h3><a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)" style="overflow: hidden;
                            position: relative;
                            line-height: 30px; 
                            /* height must be a multiple of line-height for how many rows you want to show (height = line-height x rows) */
                            height: 90px;
                            -ms-hyphens: auto;
                            -webkit-hyphens: auto;
                            hyphens: auto;
                            word-wrap: break-word;">{{item.title}}</a></h3>
                         <div class="post-desc featured-desc">
                            <div class="limit" style="overflow:hidden;display:-webkit-box;-webkit-line-clamp:4;-webkit-box-orient:vertical">
                                <p [innerHTML]="item.detail">
                                    

                                </p>


                            </div>
                        </div>
                        <a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)" style="color: #015e97;">devamını oku...</a>

                        <ul class="admin">
                            <!-- <li><a routerLink="/blog-details/{{item.id}}"><i class="bx bx-user-circle"></i>Murat</a></li> -->
                            <li class="float"><i class="bx bx-calendar-alt"></i> {{item.creationDate  | date:'dd/MM/yyyy' :'TR'}}</li>
                        </ul>
                    </div>
                </div>
            </div>


            <!-- <div class="col-lg-12">
                <div class="pagination-area">
                    <span class="page-numbers current">1</span>
                    <a routerLink="/blog-grid" class="page-numbers">2</a>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Blog Area -->