import { Component, OnInit } from '@angular/core';
import { ContentsService } from '../../services/contents.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-grid-page',
  templateUrl: './blog-grid-page.component.html',
  styleUrls: ['./blog-grid-page.component.scss']
})
export class BlogGridPageComponent implements OnInit {
  path = environment.serverUrl;
  newsModel!:any;

  // title = "Bölge Seminerleri Serisi Devam Ediyor ";

  constructor(private contentsService:ContentsService) { }

  ngOnInit(): void {
    this.getnewsList();
    
  }

createSlug(title: string): string {
  title = title.trim().toLowerCase().replace(/\s+/g, '-');
  const turkishChars = "çğıöşüÇĞİÖŞÜİ";
  const englishChars = "cgiosuCGIOSUı";
  
  let result = "";
  for (let i = 0; i < title.length; i++) {
    const index = turkishChars.indexOf(title[i]);
    if (index !== -1) {
      result += englishChars[index];
    } else {
      result += title[i];
    }
  }
  return result;
}
  getnewsList(){
    this.contentsService.getnewsList().subscribe(newsdata=> {
      this.newsModel = newsdata;
    })
  }
}
