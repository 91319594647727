<!-- <hr>
<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Son Haberler</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let item of newsModel">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details/{{item.id}}">
                        <img img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image">

                    </a>
                    <div class="single-blog-content" style="background-color: #f9fafb;">
                        <h3><a routerLink="/blog-details/{{item.id}}" style="overflow: hidden;
                            position: relative;
                            line-height: 30px; 
                            /* height must be a multiple of line-height for how many rows you want to show (height = line-height x rows) */
                            height: 90px;
                            -ms-hyphens: auto;
                            -webkit-hyphens: auto;
                            hyphens: auto;
                            word-wrap: break-word;">{{item.title}}</a></h3>
                        <p innerHTML="{{item.detail}}" style="overflow: hidden;
                        position: relative;
                        line-height: 30px; 
                        /* height must be a multiple of line-height for how many rows you want to show (height = line-height x rows) */
                        height: 90px;
                        -ms-hyphens: auto;
                        -webkit-hyphens: auto;
                        hyphens: auto;
                        word-wrap: break-word;">{{item.detail}}</p>
                        <ul class="admin">
                            <li class="float"><i class="bx bx-calendar-alt" style="color: #13b1cb;"></i> {{item.creationDate  | date:'dd/MM/yyyy' :'TR'}}</li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section> -->

<div class="container">
    <div class="section-title">
        <h2>Son Haberler</h2>
    </div>
    <div class="partner-slider">
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide *ngFor="let item of newsModel">
                <div class="partner-item">
                    <div class="single-blog-posts">
                        <a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)">
                            <img [src]="path+'/WebImages/Announcements/'+item.image" alt="Image" class="post-image">
                        </a>
                        <div class="single-blog-content">
                            <h3 class="post-title">
                                <a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)">
                                    {{item.title}}
                                </a>
                            </h3>
                            <div class="post-desc featured-desc">
                                <div class="limit">
                                    <p [innerHTML]="item.detail"></p>
                                </div>
                            </div>
                            <a [routerLink]="'/haber-detay/' + item.id + '/' + createSlug(item.title)" class="read-more">devamını oku...</a>
                            <ul class="admin">
                                <li class="float">
                                    <i class="bx bx-calendar-alt"></i> {{item.creationDate  | date:'dd/MM/yyyy' :'TR'}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
        
        
    </div>
</div>                    
