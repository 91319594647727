<section class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="counter-max-wide">
            <div class="section-title white-title">
                <!-- <span>Why Choose Us</span> -->
                <h2>İstatistik Bilgileri</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <img src="assets/img/icon/go╠ênu╠êllu╠ê say─▒s─▒.png" alt="">
                            <!-- <i class="flaticon-authentication"></i> -->
                            <h2>1000+ </h2>
                            <h3>Gönüllü sayısı</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <img src="assets/img/icon/etkinliklere kat─▒lan kis╠ği.png" alt="">

                            <!-- <i class="flaticon-reliability"></i> -->
                            <h2>500+ </h2>
                            <h3>Etkinliklere katılan kişi sayısı</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <img src="assets/img/icon/eg╠åitimlerden yararlanan.png" alt="">

                            <!-- <i class="flaticon-web-protection"></i> -->
                            <h2>400+ </h2>
                            <h3>Eğitimlerden yararlanan kişi sayısı</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <img src="assets/img/icon/sosyal medyada eris╠ğilen.png" alt="">

                            <!-- <i class="flaticon-innovation"></i> -->
                            <h2>2000+ </h2>
                            <h3>Sosyal medyada erişilen kişi sayısı</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>