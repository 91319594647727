<section class="get-in-touch-area ptb-100">
    <div class="container">
        <div class="section-title white-title">
            <span>Get In Touch</span>
            <h2>Contact Us Today To Speak With An Expert About Your Specific Needs</h2>
        </div>

        <form class="get-in-touch-form">
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" class="form-control" id="First-Name">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" id="Email">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="text" class="form-control" id="Number">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <label>Company</label>
                        <input type="text" class="form-control" id="Company">
                    </div>
                </div>
            </div>

            <button type="submit" class="default-btn">
                <span>Consultation</span>
            </button>
        </form>
    </div>
</section>